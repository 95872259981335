<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar dense class="tc-title">
      <v-toolbar-title>Company Selection Procedure</v-toolbar-title>
      <!-- <template>
                    <v-btn color="primary" class="btn-title" dark  @click="dialog=true"><v-icon>mdi-plus</v-icon>New Tpo Registration</v-btn>
                </template> -->
    </v-toolbar>
    <v-card>
      <v-card-text>
        <!-- {{company_list}} -->
        <v-row justify="center" id="cmp_list">
          <v-col md="3" sm="3">
            <v-select
              dense
              v-model="company"
              :items="company_list"
              item-text="codename"
              label="Select Company"
              persistent-hint
              hint="Select Company"
              return-object
              @change="findSelectionRoundInfo"
              single-line
            ></v-select>
          </v-col>
        </v-row>
        <v-row justify="center" id="cmp_name">
          <v-col sm="3">
            <span style="color: black; font-size: 12; font-weight: normal"
              >Comapny Name :
              <span style="color: blue; font-size: 16; font-weight: bold">
                {{ this.companyname }}</span
              >
            </span>
            <!--
            <v-combobox
              v-model="companyname"
              label="Company"
              readonly
              dense
            ></v-combobox>
            -->
          </v-col>
        </v-row>
        <v-row>
          <template v-if="company != null">
            <!-- <v-col md="3" sm="3"> 
            <v-chip
              class="ma-2"
              color="grey"
              label
              text-color="white"
            >
              Schedule Date :: {{company.from_schedule_date}}
            </v-chip>
          </v-col>
          <v-col md="3" sm="3">
            <v-chip
              class="ma-2"
              color="grey"
              label
              text-color="white"
            >
              Company Type :: {{company.companytype}}
            </v-chip>
          </v-col>
          <v-col md="3" sm="3">
            <v-chip
              class="ma-2"
              color="grey"
              label
              text-color="white"
            >
              Domain :: {{company.industry}}
            </v-chip>            
          </v-col> -->
           
            <v-col cols="12" sm="3"> </v-col>
            <v-col cols="12" sm="6">
              <v-card style="margin: 8px; height: 100%">
                <v-card-title class="btn-title2 link-icon">
                  Add Round
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="12">
                      <v-select
                        :items="this.rno"
                        item-text="id"
                        label="Round No"
                        outlined
                        v-model="roundno"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="12">
                      <v-select
                        :items="company_round_list"
                        item-text="name"
                        label="Round Details"
                        v-model="rounddetails"
                        return-object
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6" class="d-flex">
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        full-width
                        max-width="550px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <!-- <v-datetime-picker label="Select Datetime" v-model="dateFormatted"  timePickerFormat="ampm"> </v-datetime-picker>-->
                          <input
                            type="datetime-local"
                            v-model="dateFormatted"
                          />
                        </template>
                        <!-- <template v-slot:activator="{ on }">
                          <v-text-field
                            dense
                          
                            v-model="dateFormatted"                           
                            label="Round Date "
                            
                            outlined
                            @blur="date2 = parseDate(dateFormatted)"
                            v-on="on"
                          ></v-text-field>
                        </template>
                         <v-datetime-picker label="Select Datetime" v-model="date2"  v-on="on"> </v-datetime-picker>
                        <v-date-picker
                          v-model="date2"
                          no-title
                          @input="menu2 = false"
                        ></v-date-picker>-->
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="12">
                      <v-checkbox
                        v-model="checkbox1"
                        :label="`Is Active`"
                      ></v-checkbox>

                      <v-row justify="center">
                        <v-btn
                          class="btn-title"
                          dark
                          color="indigo"
                          @click="saveRoundInfo()"
                        >
                          <v-icon left>mdi-plus</v-icon>Save
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                  <!-- <v-col cols="6" sm="4">
                                <span class="lbl">Specific Criteria</span>
                            </v-col> -->
                  <!-- <v-col cols="12" md="12">
                                <v-text-field v-model="specificcriteria" label="Add Specific Criteria" type="text"></v-text-field>
                            </v-col>
                            <v-row justify="center">
                                    <v-btn class="btn-title" dark  color="indigo" @click="addspecificcriteria()"> <v-icon left>mdi-plus</v-icon>Save </v-btn>
                            </v-row>    -->
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-row>
        <!-- <v-row align="center" justify="center" v-if="company!=null" > -->
        <!-- <v-col md="4" sm="4">
            <v-chip
              class="ma-2"
              color="grey"
              label
              text-color="white"
            >
              Offering ::  {{company.offering}}
            </v-chip>            
          </v-col>  
          <v-col md="4" sm="4">
            <v-chip
              class="ma-2"
              color="grey"
              label
              text-color="white"
            >Branches
            </v-chip> 
           <li v-for="branches in company.branches" :key="branches">    
				{{ branches }}
			</li>
          </v-col> 
          <v-col md="4" sm="4">
            <v-chip
              class="ma-2"
              color="grey"
              label
              text-color="white"
            >
              Institutes ::  {{company.institutes}}
            </v-chip>            
          </v-col>                              -->
        <!-- </v-row> -->
        <template v-if="company != null"> </template>
      </v-card-text>
    </v-card>

    <!-- <v-expansion-panels
      v-model="panel"
     
	   v-if="company!=null"
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header>Add Round</v-expansion-panel-header>
        <v-expansion-panel-content>
					<v-container fluid>
					<v-row align="center">
					<v-col class="d-flex" cols="12" sm="4">
						<v-select
						:items="this.rno" 
						item-text="id"
						label="Round No"
						outlined
						v-model="roundno"
						></v-select>
					</v-col>

					<v-col class="d-flex" cols="12" sm="4">
						<v-select
						:items="company_round_list"
						item-text="name"
						label="Round Details"
						v-model="rounddetails"
						return-object
						outlined
						></v-select>
					</v-col>
					<v-col class="d-flex" cols="12" sm="4">
						<v-checkbox v-model="checkbox1" :label="`Is Active`"></v-checkbox>
					
						<v-btn class="mx-2" fab dark color="indigo" @click="saveRoundInfo">
						<v-icon dark>mdi-plus</v-icon>
						</v-btn>
					</v-col>
					</v-row>
					
				</v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->
    <v-spacer></v-spacer>
    <template v-if="company != null">
      <v-toolbar dense class="tc-title">
        <v-toolbar-title>Company Selection Procedure</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
    <v-data-table
      style="margin: 8px; height: 100%"
      :headers="headers"
      :items="this.company_offer_round_list"
      v-if="this.company_offer_round_list != null"
      class="elevation-1"
    >
      <template v-slot:top>
        <!-- <v-toolbar  dense class="tc-title"> -->
        <!-- <v-toolbar-title>Company Selection Procedure</v-toolbar-title>
                 <v-spacer></v-spacer> -->
        <!-- <template>
                    <v-btn color="primary" class="btn-title" dark  @click="dialog=true"><v-icon>mdi-plus</v-icon>New Tpo Registration</v-btn>
                </template> -->
        <!-- </v-toolbar> -->
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title class="card-title">
              <v-row justify="space-between">
                {{ formTitle }}
                <v-btn icon dark @click="dialog = false">
                  <v-icon color="black">mdi-close</v-icon>
                </v-btn>
              </v-row>
            </v-card-title>
            <!-- <v-card-title>
					<span class="headline">{{ formTitle }}</span>
					</v-card-title> -->
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <label>Round Number</label>
                    <h4>{{ editedItem.round_number }}</h4>
                  </v-col>
                  <!--v-text-field v-model="editedItem.round_number" label="Round Number" readonly=""></v-text-field-->

                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      :items="company_round_list"
                      item-text="name"
                      label="Round Details"
                      v-model="editedItem.round_details"
                    ></v-select>
                  </v-col>
                  <v-col sm="6">
                    <v-menu
                      ref="menu2_edit"
                      v-model="menu2_edit"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <!--    <v-text-field
                                dense
                                rounded
                                v-model="dateFormatted_edit"
                                label="Edit Date"                              
                                persistent-hint
                                outlined
                                @blur="
                                  date2_edit = parseDate(dateFormatted_edit)
                                "
                                v-on="on"
                              ></v-text-field> -->
                        <input
                          type="datetime-local"
                          v-model="dateFormatted_edit"
                        />
                      </template>

                      <!--   <v-date-picker
                              v-model="date2_edit"
                              no-title
                              @input="menu2_edit = false"
                            ></v-date-picker>-->
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-checkbox
                      dense
                      v-model="editedItem.round_isactive"
                      color="success"
                      label="Is Active?"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-checkbox
                      dense
                      v-model="editedItem.round_isfinal"
                      color="success"
                      label="Is Final?"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="editSave(editedItem)"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- </v-toolbar> -->
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-btn text>
          <v-icon color="red" @click="deletefun(item.id)">
            mdi-delete</v-icon
          ></v-btn
        >
      </template>
       <template v-slot:item.isnotificationrequired="{ item }">
        <!--v-switch v-model="item.round_isfinal" @click="changeFlag(item)"></v-switch-->
        <v-switch
          v-model="item.isnotificationrequired"
          @change="changeNotification(item)"
          :labels="{ checked: 'On', unchecked: 'Off' }"
          style="margin-left: 20px"
        />
      </template>
      <template v-slot:item.round_isfinal="{ item }">
        <!--v-switch v-model="item.round_isfinal" @click="changeFlag(item)"></v-switch-->
        <v-switch
          v-model="item.round_isfinal"
          @change="changeFlag(item)"
          :labels="{ checked: 'On', unchecked: 'Off' }"
          style="margin-left: 20px"
        />
      </template>
      <template v-slot:item.round_isactive="{ item }">
        <!--v-switch v-model="item.round_isactive" @click="changeFlagActive(item)"></v-switch-->
        <v-switch
          v-model="item.round_isactive"
          @change="changeFlagActive(item)"
          :labels="{ checked: 'On', unchecked: 'Off' }"
          style="margin-left: 20px"
        />
      </template>
    </v-data-table>
    <div class="text-center">
      <v-btn
        class="btn-title ma-5 mt-8 center"
        dark
        color="indigo"
        @click="toCriteria()"
      >
        Add Criteria<v-icon>mdi-arrow-left</v-icon> Previous</v-btn
      >

      <v-btn
        class="btn-title ma-5 mt-8 center"
        dark
        color="indigo"
        @click="toOnCampus()"
      >
        Next <v-icon>mdi-arrow-right</v-icon>On Campus</v-btn
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    menu2: false,
    menu2_edit: false,
    menu1: false,
    menu1_edit: false,
    display_size: null,
    dateFormatted_edit: "",
    edit_dateFormatted_end: "",
    dateFormatted: "",
    dateFormatted_end: "",
    date2: "",
    date2_end: "",
    edit_dateFormatted_end: "",
    dateFormatted_edit: "",
    date2: new Date().toISOString(),
    date2_edit: "",
    date2_end: new Date().toISOString().substr(0, 10),
    snackbar_msg: "",
    snackbar: false,
    color: "",
    panel: [0],
    rounddetails: null,
    roundno: "",
    dialog: false,
    checkbox1: true,

    //
    company_list: null,
    company_round_list: null,
    company_offer_round_list: null,
    company: null,
    branches: "",
    rno: [
      { id: 1, value: "1" },
      { id: 2, value: "2" },
      { id: 3, value: "3" },
      { id: 4, value: "4" },
      { id: 5, value: "5" },
      { id: 6, value: "6" },
      { id: 7, value: "7" },
      { id: 8, value: "8" },
      { id: 9, value: "9" },
      { id: 10, value: "10" },
    ],

    headers: [
      {
        id: "Dessert (100g serving)",
        round_number: "",
        round_details: "",
        round_date: "",
        isnotificationrequired: false,
        round_isfinal: false,
        round_isactive: false,
      },
      { text: "Round Number", value: "round_number" },
      { text: "Round Name", value: "round_details" },
      { text: "Round Date", value: "round_date" },
       { text: "Is Notification Required", value: "isnotificationrequired" },
      { text: "Is Final", value: "round_isfinal" },
      { text: "Is Active", value: "round_isactive" },
      { text: "Action", value: "actions" },
    ],
    editedIndex: -1,
    editedItem: {
      id: "",
      round_number: "",
      round_details: null,
      round_date: null,
      round_isfinal: false,
      round_isactive: false,
      dateFormatted_edit: "",
      company_round_id: "",
    },
    defaultItem: {
      id: "",
      round_number: "",
      round_details: null,
      round_date: null,
      round_isfinal: false,
      round_isactive: false,
      company_round_id: "",
    },
    scheduling_id: null,
    companyname: null,
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    date2(val) {
      this.dateFormatted = this.formatDate(this.date2);
    },
    date2_end(val) {
      this.dateFormatted_end = this.formatDate(this.date2_end);
    },
    date2_edit(val) {
      this.dateFormatted_edit = this.formatDate(this.date2_edit);
    },
    edit_date2_end(val) {
      this.edit_dateFormatted_end = this.formatDate(this.edit_date2_end);
    },
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toCriteria() {
      this.$router.push({
        name: "tpo-create-criteria",
        params: {
          scheduling_id: this.$route.params.scheduling_id,
          cmp_name: this.$route.params.cmp_name,
          item: this.$route.params.item,
        },
      });
    },
      changeNotification(item) {
      // if (!confirm("Are you sure, you want to change status!!!")) return unchecked;
      const formData = {
        company_offer_round_list: item,
      };
      axios
        .post("/SelectionProcedure/changeNotification", formData)
        .then((res) => {
          if (res.data.msg == "200") {
            //4CAF50
            this.showSnackbar("#4CAF50", "Successfully Changed status...");
            this.company_offer_round_list = res.data.cor_list;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => {});
    },

    toOnCampus() {
      var data={};
      if(this.scheduling_id==null)
      {
 data = {
        companyoffering: this.company.id,
      };
      }
      else{
  data = {
        companyoffering: this.scheduling_id,
      };
      }
      axios
        .post("/SelectionProcedure/checkisfinal", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.$router.push({
              name: "tpo-company-campus-process",
              params: {
                scheduling_id: this.$route.params.scheduling_id,
                cmp_name: this.$route.params.cmp_name,
                item: this.$route.params.item,
              },
            });
          } else {
            this.showSnackbar("#b71c1c", "Please Finalize one round");
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    editItem(item) {
      this.editedIndex = this.company_offer_round_list.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if(this.editedItem.round_date != '' && this.editedItem.round_date !='null' &&this.editedItem.round_date != null)
      { 
        this.dateFormatted_edit = this.editedItem.round_date
        .split(".")[0]
        .trim()
        .replace(" ", "T");
      }
      //alert(this.dateFormatted_edit);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deletefun(item) {
      //console.log("a");
      const data = {
        deleteitem: item,
      };
      axios
        .post("/SelectionProcedure/deleteSElectionRound", data)
        .then((res) => {
          if (res.data.code == "SUCCESS") {
            this.showSnackbar("#4caf50", "Deleted Successfully...");
            this.findSelectionRoundInfo();
          } else {
            //error
            this.showSnackbar("#b71c1c", res.data.msg);
          }
         
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
    changeFlag(item) {
      // if (!confirm("Are you sure, you want to change status!!!")) return unchecked;
      const formData = {
        company_offer_round_list: item,
      };
      axios
        .post("/SelectionProcedure/finalizeRoundInfo", formData)
        .then((res) => {
          if (res.data.msg == "200") {
            //4CAF50
            this.showSnackbar("#4CAF50", "Successfully Changed status...");
            this.company_offer_round_list = res.data.cor_list;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => {});
    },

    changeFlagActive(item) {
      //if (!confirm("Are you sure, you want to change status!!!")) return;
      const formData = {
        company_offer_round_list: item,
      };
      axios
        .post("/SelectionProcedure/activeRoundInfo", formData)
        .then((res) => {
          if (res.data.msg == "200") {
            //4CAF50
            this.showSnackbar("#4CAF50", "Successfully Changed status...");
            this.company_offer_round_list = res.data.cor_list;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show alertsnackbar
        })
        .finally(() => {});
    },
    editSave(editedItem) {
      const data = {
        editedItemdata: editedItem,
        round_date2: this.dateFormatted_edit,
      };
      axios
        .post("/SelectionProcedure/editSaveRoundInfo", data)
        .then((res) => {
          if (res.data.msg == "200") {
            //4CAF50
            this.showSnackbar("#4CAF50", "Successfully Updated Record...");
            this.company_offer_round_list = res.data.cor_list;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })

        .finally(() => {});

      this.close();
    },
    findSelectionRoundInfo() {
      var data={};
      if(this.scheduling_id==null)
      {
 data = {
        companyoffering: this.company.id,
      };
      }
      else{
  data = {
        companyoffering: this.scheduling_id,
      };
      }
     
      axios
        .post("/SelectionProcedure/findSelectionProce", data)
        .then((res) => {
          if (res.data.msg == "200") {
            //4CAF50
            this.company_offer_round_list = res.data.cor_list;
            //console.log("sd fhs"+this.company_offer_round_list)
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => {});
    },
    findSelectionRoundInfobyId() {
      const data = {
        companyoffering: this.scheduling_id,
      };
      axios
        .post("/SelectionProcedure/findSelectionProcebyid", data)
        .then((res) => {
          if (res.data.msg == "200") {
            //4CAF50
            this.company_offer_round_list = res.data.cor_list;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => {});
    },
    saveRoundInfo() {
      // alert("format date : "+this.dateFormatted)
      // if (this.dateFormatted == null || this.dateFormatted == "") {
      //   this.showSnackbar("#b71c1c", "Please select the date..."); // show snackbar
      // } 
      // else {
        var data = {}
        if(this.scheduling_id==null)
        {
           data = {
          rounddetails: this.rounddetails,
          roundno: this.roundno,
          companyoffering: this.company.id,
          round_isactive: this.checkbox1,
          round_date: this.dateFormatted,
        };
        }

        else{ data = {
          rounddetails: this.rounddetails,
          roundno: this.roundno,
          companyoffering: this.scheduling_id,
          round_isactive: this.checkbox1,
          round_date: this.dateFormatted,
        };}
        

        axios
          .post("/SelectionProcedure/saveSelectionProce", data)
          .then((res) => {
            if (res.data.msg == "200") {
              //4CAF50
              //this.company_list = res.data.company_list
              this.company_offer_round_list = res.data.cor_list;
              (this.roundno = ""),
                (this.rounddetails = ""),
                (this.checkbox1 = true),
                this.showSnackbar("#4CAF50", "Successfully Added Record...");
            } else {
              this.showSnackbar("#b71c1c", res.data.msg); // show snackbar
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Someting Went Wrong..."); // show snackbar
          })
          .finally(() => {
            this.dateFormatted = null;
          });
      
    },
  },
  mounted() {
    document.getElementById("cmp_list").style.display = "none";
    document.getElementById("cmp_name").style.display = "none";
    this.scheduling_id = this.$route.params.scheduling_id;
    this.companyname = this.$route.params.companyname;
    this.company = this.$route.params.scheduling_id;
   
     if(this.scheduling_id==undefined)
   {
     this.scheduling_id=localStorage.getItem("scheduling_id")
   }

     if(this.companyname==undefined)
   {
     this.companyname=localStorage.getItem("companyname")
   }

    
    //console.log(this.company);
     if(this.company==undefined)
   {
     this.company=localStorage.getItem("scheduling_object")
   }
    //console.log(this.company);
    

    if (this.scheduling_id) {
      document.getElementById("cmp_list").style.display = "none";
      document.getElementById("cmp_name").style.display = "block";
    } else {
      document.getElementById("cmp_list").style.display = "block";
      document.getElementById("cmp_name").style.display = "none";
    }

    axios
      .post("/SelectionProcedure/selectionProce")
      .then((res) => {
        if (res.data.msg == "200") {
          //4CAF50
          this.company_list = res.data.company_list;
          this.company_round_list = res.data.company_round_list;
          if (this.company) {
            this.findSelectionRoundInfobyId();
          }
        }
      })
      .catch((error) => {
        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
      })
      .finally(() => {});
  },
};
</script>

<style scoped>
.ec-title {
  background-image: linear-gradient(-100deg, #f4faba, #d64907);
  color: #fff;
}
.c-title {
  background-image: linear-gradient(-90deg, #befcf7, #03948d);
  color: #fff;
}
.tc-title {
  background-image: linear-gradient(-90deg, #fff, #057996);
  color: #fff;
  border-radius: 3px;
}
.btn-title {
  background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
  color: #fff;
}
.card-title {
  background-image: linear-gradient(-90deg, #8edffd, #034c70);
  color: #fff;
}
.lbl {
  color: #1705e6;
}
.link-icon {
  color: #fff;
}
.btn-title2 {
  background: linear-gradient(-90deg, #30cfd0 0%, #330867 100%);
}
</style>
